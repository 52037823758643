import React from 'react';
import PropTypes from 'prop-types';

const QuoteRight = (props) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1792 1792">
      <path fill={props.fill} d="M832 960v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z" />
    </svg>
  );
};

QuoteRight.propTypes = {
  fill: PropTypes.string,
};

export default QuoteRight;