import React from 'react';
import PropTypes from 'prop-types';

const QuoteLeft = (props) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1792 1792">
      <path fill={props.fill} d="M832 320v704q0 104-40.5 198.5t-109.5 163.5-163.5 109.5-198.5 40.5h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64q106 0 181-75t75-181v-32q0-40-28-68t-68-28h-224q-80 0-136-56t-56-136v-384q0-80 56-136t136-56h384q80 0 136 56t56 136zm896 0v704q0 104-40.5 198.5t-109.5 163.5-163.5 109.5-198.5 40.5h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64q106 0 181-75t75-181v-32q0-40-28-68t-68-28h-224q-80 0-136-56t-56-136v-384q0-80 56-136t136-56h384q80 0 136 56t56 136z" />
    </svg>
  );
};

QuoteLeft.propTypes = {
  fill: PropTypes.string,
};

export default QuoteLeft;